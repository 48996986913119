/* permissions.css */

/* Global styles */

.Title {
  margin-top: 10px;
  margin-bottom: 20px;
  align-items: left;
  align-content: left;
  text-align: left;
  
}

.PermissionManager {
    font-family: 'Open Sans', sans-serif; /* Change to Google Fonts */
    font-size: 14px;
    text-align: center;
    margin-top: 20px;
    padding: 20px;
    overflow-x: auto;  
    overflow-y: auto; 
    display: block;  
    max-height: 400px; 
    width: 100%;
    /* background-color: #008000;      */
  }
  
 
  /* Permissions Container */
  .PermissionsContainer {
    background-color: #f8f8f8;
    padding: 15px;
     max-height: 500px;  
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: grid; /*grid*/
    grid-template-columns: repeat(5, 1fr); /* Display 5 regions in a row */
    gap: 15px;
    width: 90%; /* Adjust width as needed */
    margin: 5 auto; /* Center the container horizontally */                
     white-space: unset;  
     overflow-y: auto;    
     overflow-x: auto;    
  }
  
  /* Region styling */
  .RegionCard {
    padding: 20px;    
    text-align: left;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: background-color 0.2s ease-in-out, transform 0.2s ease-in-out;
    width: 80%;
    cursor: pointer;
    margin-right: 20px;    
  }    
  .RegionCard:hover {
    background-color: #e0e0e0;
    transform: translateY(-3px);
  }  
  .RegionCard.selected {
    background-color: #c0e6c0;
    box-shadow: 0 4px 10px rgba(0, 128, 0, 0.2);
    transform: translateY(-3px);
  }

  .RegionCard.disabled {
    background-color: #cbd9cb;;
    box-shadow: 0 4px 10px rgba(0, 128, 0, 0.2);
    transform: translateY(-3px);
    
  }
  
  .RegionCard.indeterminate {
    background-color: #f0e68c;  
    box-shadow: 0 4px 10px rgba(139, 69, 19, 0.2);  
    transform: translateY(-3px);
    
  }  
  .RegionLabel {
    /* display: flex; */
    
    padding-right: 5px;
    
    height: 35px !important;
    /* padding-bottom: 20px;   */
    
    color: #672c2c;  
    
  }

  .RegionCheckbox {
    width: 20px;
    height: 20px;
    padding-right: 20px !important ; 
    
    /* padding-bottom: 10px;     */
    transition: background-color 0.2s ease-in-out;   
  }  
  
  .PermissionLabel {
    display: flex;
    height: 20px;
    /* display: contents; */
    /* align-items: end;  */
      padding-left: 40px !important;   
    /* font-size: 20px !important; */
 
   
    color: #100d1e;  
  }
  
  .PermissionCheckbox {
    /* width: 30px; */
    /* height: 20px; */
      /* padding-top: 10px;   */
      
     
    padding-left: 20px  ; 
    /* padding-top: 10px;   */     
    transition: background-color 0.2s ease-in-out;
   
  }
  
  .Permission:hover   {
    color: #333;
  }
  
  .Permission.selected   {
    color: #008000;
    font-weight: bold;
  }
  
   
  
  /* Button styling */
  .ButtonContainer {
    margin-top: 20px;
    display: flex;
    justify-content: center; /* Align items to the left */
    align-items: start;
  }
  
  .SaveButton {
     align-items: flex-start;
    background-color: #05a005 !important ;
    color: #f6f4f4 !important;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
  }
  .SaveButton:disabled {
    background-color: #d7d8d7 !important; /* Lighter green to indicate disabled */
    color: #ce1818;
    cursor: not-allowed;
  }
  .SaveButton:hover {
    background-color: #006400 !important;
  }  

   