/* HelpDocuments.css */
.help-documents-container {
  margin-top: 10px;  
  display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #ffffff;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  
  .fetch-button {
    background-color: #4CAF50; /* Green */
    color: white;
    padding: 14px 20px;
    margin: 15px 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s, box-shadow 0.3s;
    font-size: 16px;
    font-weight: 510;
  }
  
  .fetch-button:hover {
    background-color: #45a049;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }
  
  .fetch-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  
  .help-document-content {
    max-width: 600px;
    background: white;
    padding: 20px;
    margin-top: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
  }
  
  .help-document-content:hover {
    transform: scale(1.02);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
  
  pre {
    text-align: left;
    color: #333;
    overflow: auto;
  }
  