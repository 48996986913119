.containerX {
    display: flex;
    justify-content: flex-start;
    margin-left: 0;  
  }
  .content-containerX {
    flex: auto;
  }
  .card-content {
    display: flex;
    flex-direction: column;
    position: relative;  
  }
  
 /*Not used*/
  .card {
    margin-bottom: 20px;
    transition: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add shadow */
    cursor: default;
    background-color: transparent;
    border: 1px solid #b42f2f; /* Add border */
  }
  
  .smallItalicRed {
    font-size: small;
    font-style: italic;
    color: rgb(240, 14, 14);
  }
  
  .error-button {
    color: red !important;
  }
  .description {
    color: #26292b;
    margin-bottom: 10px;
  }
  
  .date {
    color: #666;
  }
  .greenForDate {
    color: green;
    font-weight: bold !important;
  }
  

  .pagination {
    max-width:"md";
    position: fixed;
    bottom: 0;
    left: auto;
    width: 100%;
    background: white;
    padding: 10px;
    display: flex;
    justify-content: center;
    padding-bottom: 30px;
  }
  
  .pagination button {
    margin-right: 5px;
  }
  