 
.containerResult {
    display: flex;  
    justify-content: center;  
    align-items: center;  
    /* height: 100vh;   */
    width: 100%;  
    padding: 2px;
    margin: 0;  
   
} 
 
.result-group label,
.result-group input
 {
    margin: 5px;
    font-size: 16px;
}

.result-group label {
    font-weight: bold;
    white-space: nowrap;  
}
 
.result-group input {
    height: 40px;
    border: 1px solid #ccc;    
    border-radius: 5px;
    outline: none;
    cursor: pointer;
     
    width: 250px;
    box-sizing: border-box;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    padding-left: 10px;    
}
 
 
