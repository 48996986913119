.not-found-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 90vh;
  padding-top: 10vh;
  text-align: center;
}

.not-found-heading {
  margin-bottom: 16px;
  color: #1976d2; /* Primary color */
  font-weight: 700;
}

.not-found-subheading {
  margin-bottom: 32px;
  color: #616161; /* Secondary text color */
}

.not-found-button {
  margin-top: 16px;
  padding: 10px 24px;
  font-size: 1rem;
  font-weight: 600;
}
