.dashboard-container {
    display: flex;
    align-items: flex-start;  
    justify-content: flex-start;  
    /* padding: 2px; */
    padding-bottom: 0px;
    flex-wrap: wrap;
    gap: 15px;
  }  
  
  .card {
    background-color: white;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 170px;
    height: 170px;
    transition: transform 0.2s, box-shadow 0.2s, color 0.2s;
    cursor: pointer;

  }
  .card:hover {
    transform: scale(1.05);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  /* cursor: pointer; */
  background-color: #f2f2f2;
  color: #333;
  }
  
  .icon-container {
    font-size: 24px;
    margin-bottom: 8px;
  }
  
  .value {
    font-size: 20px;
    font-weight: bold;
  }
  .valueSmall {
    font-size: 12px;
    font-weight: bold;
  }
  
  .labelCard {
    font-size: 14px;
    color: #8b8484 !important;
    /* Add some margin to the label to separate it from the value */
    margin-top: 8px;
  }
  
  /* Styles for vertical cards */
/* .vertical-cards {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    font-size: 10;
    min-width: 230px;
    height: 90px;  
  } */