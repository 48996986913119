.containerSearch {
    display: flex; 
    justify-content: center;  
    align-items: center;  
    width: 100%;  
    padding: 2px;
    margin: 0 auto; 
    
}

.selection-group {
    display: flex; 
    flex-direction: row; 
    align-items: center;  
    width: 100%;  
    padding-bottom: 1px;
    justify-content: center; 
}

.selection-group label,
.selection-group input,
.selection-group select {
    margin: 5px;
    font-size: 16px;
}

.selection-group label {
    font-weight: bold;
    white-space: nowrap;  
}

.selection-group select {
    width: 110px;
    height: 40px;
    border: 1px solid #ccc; 
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);   
    border-radius: 5px;
    outline: none;
    cursor: pointer;
    padding-left: 10px;
    padding-right: 10px;
}

.selection-group input {
    width: 250px;
    height: 40px;
    border: 1px solid #ccc;    
    border-radius: 5px;
    outline: none;
    cursor: pointer;
    padding-left: 10px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
}
