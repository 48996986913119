.mapContainer {
    display: flex;         
    flex-direction: column;  
    align-items: center;  
    justify-content: center;  
    width: 70vw;           
    height: 70vh;         
    border-radius: 10px;   
    border: 5px solid #02c;  
    padding: 5px;          
    margin: 5px auto;     
    text-align: center;    
  }
  