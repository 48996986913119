/* WaitingTable.css */
 
.waitingTableContainer {
  margin: 10px;
  margin-left: 3px;
  margin-top: 1px; 
  /* max-width: 800px; */
  width: 100%;
  /* min-width: 800px; */
  max-height: 450px;  
  overflow: auto;
}

.waitingTable {
  width: 100%; 
  padding-top: 10px;
  border-collapse: collapse;
  border : 1px solid #ddd ;
 
}

.waitingTableHeader {
  color: #151414;
  text-align: left;
  position: sticky;
  top: -2px;
  z-index: 1;
  background-color: #f4f4f4; /* Use a neutral background color */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Add a subtle box-shadow for the border effect */
  font-weight: bold;
  cursor: pointer;
  border : 1px solid #f3f2f2;
  padding: 10px;
  
  height: 50px;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-size: small;
}

 .waitingTableCell {
  padding: 5px;
  text-align: left;
   
  border : 1px solid #f3f2f2;
  font-family: 'system-ui','Lato', sans-serif;
}  

 

.waitingTableCell.dateColumn {
  color: rgb(43, 37, 195);
  cursor: pointer;
}

/*.dataTableRow:nth-child(even) {
  background-color: #f9f9f9;
}*/

.waitingTableCell:first-child {
  text-align: left;  
  min-width: 150px;  
  color: #080808;
  font-family: 'system-ui','Lato', sans-serif;

}
.waitingTableRow:hover {
  background-color: #f6f7f8;
  transition: background-color 0.3s ease;
}
 

.waitingTableRow:last-child {
  background-color: #f3f1f1;
  color: #000000;
  font-weight: bold;
  position: sticky;
  bottom: 0;
}


