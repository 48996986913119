/* TopCourses.css */

/* Styles for the container */
.top-container {
  padding: 20px;
  margin: 0 auto;
  max-width: 100%;
}

/* Styles for the chart container */
.chart-containersXXX {
  position: relative;
  width: 100%;
}

.chart-container {
  width: 100%; /* Adjust if necessary */
  display: flex;
  justify-content: center;
  align-items: center;
   
}
/* Custom styles for the legend to make it vertical on the right */
.chart-container .recharts-legend-wrapper {
  position: absolute;
  top: 100%;
  right: -5px;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  font-size: 10px; /* You can adjust the font size as needed */
}

/* Media query for small screens (max-width: 600px) */
@media (max-width: 600px) {
  .chart-container .recharts-responsive-container {
    width: 100%;
    height: 100%;
  }

  .chart-container .recharts-legend-wrapper {
    font-size: 10px;
  }
}
