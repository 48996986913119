/* Ticker.css */
.ticker-container {
    overflow: hidden;
    position: absolute;
    /* margin-left: 12px; */
    /* padding-left: 50px; */
    top: 68px; /* Adjust the distance from the top */
    margin-left: -15px;
    
   
    /* left: 10; */
    width: 89%;
    
    background-color: #2e2e35;
    box-shadow: 0 2px 4px rgba(78, 79, 81, 0.8); /* Add a subtle shadow effect */
     /* padding: 2px 0 ;   */
    
    border-radius: 0 0 15px 15px;  
  }
  
  .ticker {
    display: flex;
    position: relative;
    align-items: center;  
  }
  
  .ticker-item {
    padding: 10px;
    padding-left: 100px;
   
    font-size: 14px;
    color: #b6b4b4;
    white-space: nowrap;
    margin-right: 50px; /* Adjust the spacing between headlines */
    animation: scroll-ticker 35s linear infinite; /* Adjust the animation duration */
    animation-fill-mode: forwards; /* Keep the end state of the animation */
  }
  
  /* CSS Animation for the Ticker */
  @keyframes scroll-ticker {
    0% {
      transform: translateX(100%); /* Start from the right edge */
    }
    100% {
      transform: translateX(-100%); /* Move beyond the left edge */
    }
  }
  