/* MonthlyTable.css */
.monthTableContainer {
    margin: 10px;
    margin-left: 3px;
    margin-top: 1px; 
    /* max-width: 800px; */
    width: 100%;
    /* min-width: 800px; */
    max-height: 450px;  
    overflow: auto;    
}
.monthTable {
    padding-top: 10px;
    width: 100%;
    /* border-collapse: collapse;     */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border : 1px solid #f3f2f2;
}
.monthTableHeader {
    color: #151414;
    text-align: left;
    position: sticky;
    top: -2px;
    z-index:2;
    background-color: #f4f4f4; 
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);  
    font-weight: bold;
    cursor: pointer;
    border : 1px solid #d9d6d6;
    padding: 5px;
    min-width: 120px;
    height: 50px;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-size: small;
}
 
/*.monthTableHeader {
    padding: 5px;
    min-width: 120px;
    
    font-family: 'system-ui','Lato', sans-serif;
    font-size: small;
}*/
.monthTableCell
 {
    font-family: 'system-ui','Lato', sans-serif;
    padding: 5px;
    border : 1px solid #f3f2f2;  
}

/* .monthTableCell:first-child {
    text-align: left;  
    min-width: 220px;  
    color: #0a0a0a;
    font-family: 'system-ui','Lato', sans-serif;
  
} */

.monthTableCell:not(:first-child) {
    text-align: left;  
    min-width: 100px;      
}
 
.monthTableRow:hover {
    background-color: #f6f7f8 !important;
    transition: background-color 0.3s ease;
    
    cursor: pointer;
}
.rowSelected {
    border: 2px solid rgb(55, 31, 31);
    background-color: rgb(236, 233, 233); 
     
}


.monthTableTitle {
    color: #fbfbfb;
}
 
.monthTableHeader, .monthTableCell {
    min-width: 120px; /* example width, adjust as needed */
    font-weight: bold;
    color: #020202; 
}

.monthTableHeader:first-child {
   min-width: 230px;
    z-index: 4;
    position: sticky;
    left: 0;  
    font-family: 'system-ui','Lato', sans-serif;
    
    font-weight: bold;
    color: #020202;
   
}
 .monthTableCell:first-child {
    min-width: 230px;
    z-index: 3;
    position: sticky;
    left: 0;  
    font-family: 'system-ui','Lato', sans-serif;
    background-color: #f1f2f3; 
     
    font-weight: bold;
    color: #020202;
   
}
.monthTableHeader:last-child, .monthTableCell:last-child {
    width: 200px;  
    position: sticky;
    right: 0;
    
    font-weight: bold;
    color: #020202; 
    background-color: #f4f4f4;
}


/* .monthTableCell:last-child {
    font-weight: bold;
    color: #020202;  
    
} */
/* Highlight the Total row (footer) with a different background color */

.monthTableRow:last-child {
    background-color: #f1f2f3;
    color: #000000;
    font-weight: bold;
    position: sticky;
    border: 1px solid #191818;   
    bottom: 0;    
    z-index: 4;   
}  
/* Styles for toggle buttons */
.toggleButton {
  background-color: #cbccce; /* Dark grayish blue */
  color: #ecf0f1; /* Light grayish blue text color */
  border: none;
  padding: 10px 20px;
  margin: 2px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.toggleButton:hover {
  background-color: #34495e; /* Slightly darker on hover */     
}

/* Styles for toggled buttons */
.toggleButton.toggled {
  background-color: #3498db; /* Green color for toggled state */
}

.toggleButton.toggled:hover {
  background-color: #256a99; /* Darker green for toggled state on hover */
}
 