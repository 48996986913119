/* DataExportButtons.css */

.data-export-buttons {
    display: inline-flex;
    /* padding-left: 20px; */
    background-color: aquamarine;
  }
  
  .export-button {
    padding: 10px 10px;
    font-size: 14px;
    border: 1px solid #34495e; /* Dark blue-gray border */
    background-color: #8e9093; /* Dark blue-gray background */
    color: #ecf0f1; /* Very light gray text */
    cursor: pointer;
  
    margin-right: 0; /* No margin between buttons */
    transition: background-color 0.3s ease;
    
  }
  .export-bank-button {
    padding: 10px 10px;
    font-size: 14px;
    border: 1px solid #223242; /* Dark blue-gray border */
    background-color: #5d6570; /* Dark blue-gray background */
    color: #ecf0f1; /* Very light gray text */
    cursor: pointer;
  
    margin-right: 0; /* No margin between buttons */
    transition: background-color 0.3s ease;
    
  }
  .export-bank-button:hover {
    background-color: #181d22;
    
  }
  
  .export-button:hover {
    background-color: #181d22;  
  }
  
  .export-button:disabled {
    background-color: #bdc3c7;
    border-color: #bdc3c7;
    color: #7f8c8d;
    cursor: not-allowed;
  }
  