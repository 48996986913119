.clicked-row {
    background-color: #1d2374; /* Gray background */
    font-weight: bold !important;
  }
  
  .Mui-selected {
   background-color: #edf4fb !important ; /* Blue */
   color: #000000; /* White text */
   font-weight: bold
 }
  .admin-row {
     color: #e90729;  
  }
  .admin-row-selected {
   background-color: #edf4fb; 
   color: #e90729; 
   font-weight: bold;  
}
/* .error-row {
     color: #f1dce0;  
     background-color: #f691a0;
  } */

  
  .inactive-row {
     color: #8a8585;  
     font-style: italic; 
  }
  .inactive-row-selected {
    
   background-color: #edf4fb; 
   color: #8a8585; 
   font-style: italic;
   font-weight: bold;  
 }
 