 .searchContainer {
   
  display:      flex;
  align-items: center;
  margin: 0px;
  border-color: "red"  ;
  padding-left: 10px;
  align-items: end;
  
}  

.searchInput {
  flex: 1;   
  padding: 8px;
  font-size: 14px;
  border: 1px solid #9f9898  ;
  border-radius: 3px;
  outline: none;
 
  width: 100%;  
  
}
 
.searchInput:focus {
  border-color: rgb(103, 101, 101) ;  
}

.closeButton {
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
  background-color:transparent;
  border: none;
  position: absolute;
  right: 25px;
  top:1px;
  border-radius: 1px;
  width: 2px;
  margin-bottom: 15px;

}

.closeButton:hover {
  color: #359ee9;
}
  